import React, { useState, Fragment, useMemo } from 'react';
import { Collapse, List } from '@mui/material';
import Link from 'next/link';
import { getRoute } from '@utils';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import {
  MenuButtonListItem,
  MenuButtonListSubItem,
  MenuListItemButton,
  ListItemTypography,
  ListSubItemTypography,
  LinkRedirect,
} from '../styles';

export function HelpSection() {
  const [openHelp, setOpenHelp] = useState(false);

  const handleClickHelp = () => {
    setOpenHelp(!openHelp);
  };

  const helpOptions = useMemo(
    () => [
      {
        name: 'Contáctanos',
        redirect:
          'https://api.whatsapp.com/send?phone=56972622626&text=Hola!%20Les%20escribo%20porque%20quiero',
      },
      {
        name: 'Consultas Químico',
        route: getRoute('pharmaceutical').toLink(),
      },
      {
        name: 'Devoluciones',
        route: getRoute('devolutions').toLink(),
      },
      {
        name: 'Sugerencias y reclamos',
        route: getRoute('suggestions').toLink(),
      },
    ],
    [],
  );

  return (
    <>
      <MenuButtonListItem openItem={openHelp} onClick={handleClickHelp}>
        <ListItemTypography>Ayuda</ListItemTypography>
        {openHelp ? (
          <MenuListItemButton>
            <ExpandLess />
          </MenuListItemButton>
        ) : (
          <MenuListItemButton>
            <ExpandMore />
          </MenuListItemButton>
        )}
      </MenuButtonListItem>

      <Collapse in={openHelp} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {helpOptions.map((option, index, row) => (
            <Fragment key={option.name}>
              {option.redirect ? (
                <Link
                  href={`https://api.whatsapp.com/send?phone=56972622626&text=Hola!%20Les%20escribo%20porque%20quiero`}
                  passHref
                >
                  <LinkRedirect target="_blank">
                    <MenuButtonListSubItem>
                      <ListSubItemTypography>
                        {option.name}
                      </ListSubItemTypography>
                    </MenuButtonListSubItem>
                  </LinkRedirect>
                </Link>
              ) : (
                <Link {...option.route}>
                  <MenuButtonListSubItem lastItem={index === row.length - 1}>
                    <ListSubItemTypography>{option.name}</ListSubItemTypography>
                  </MenuButtonListSubItem>
                </Link>
              )}
            </Fragment>
          ))}
        </List>
      </Collapse>
    </>
  );
}
